import React from "react";
import { SEO, PageLayout, Background } from "@bluefin/components";
import { Header, Grid, List } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class AccessibilityPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
        />
        <PageLayout
          hero={
            <Background
              columns={1}
              stackable={true}
              className={"accessibility-hero"}
            />
          }
          subfooter={false}
          className={"accessibility-page"}
        >
          <Header as={"h1"} textAlign={"center"}>
            ACCESSIBILITY STATEMENT
          </Header>
          <Grid className={"about-content"} centered={true}>
            <Grid.Column textAlign={"left"} width={14}>
              <p>
                Big Tunas Beach Bar & Grill is committed to providing a website
                that is accessible to the widest possible audience, regardless
                of circumstance and ability.
              </p>
              <p>
                We aim to adhere as closely as possible to the Web Content
                Accessibility Guidelines, published by the World Wide Web
                Consortium. These guidelines explain how to make Web content
                more accessible for people with disabilities. Conformance with
                these guidelines will help make the web more user-friendly for
                everyone.
              </p>
              <p>
                Whilst Big Tunas Beach Bar & Grill strives to adhere to the
                guidelines and standards for accessibility, it is not always
                possible to do so in all areas of the website and we are
                currently working to achieve this. Be aware that due to the
                dynamic nature of the website, minor issues may occasionally
                occur as it is updated regularly. We are continually seeking
                solutions that will bring all areas of the site up to the same
                level of overall web accessibility.
              </p>
              <p>
                <span>
                  If you have any comments or suggestions relating to improving
                  the accessibility of our site, please don't hesitate to
                  contact our accessibility coordinator Big Tunas Beach Bar &
                  Grill by calling (386) 677-1235 or emailing&nbsp;
                </span>
                <a href={"mailto:bigtunasmarketing@gmail.com"}>
                  bigtunasmarketing@gmail.com
                </a>
                <span>&nbsp;Your feedback will help us make improvements</span>
              </p>
              <p>
                <h6>Physical Location</h6>
                <List
                  items={[
                    "2695 N Atlantic Ave, Daytona Beach, FL 32118",
                    "Big Tunas Beach Bar & Grill is committed to all people and providing services.",
                  ]}
                />
              </p>
              <p>
                <h6>ADA Compliant Wheelchair Accessibility</h6>
                <List
                  items={[
                    "Wheelchair Accessible parking spot in the parking lot",
                    "Wheelchair Accessible table access",
                    "Wheelchair Accessible bathroom",
                  ]}
                />
              </p>
              <p>
                <h6>Languages Offered for Translation</h6>
                <List
                  items={[
                    "English",
                    "Spanish (translation services by request)",
                  ]}
                />
              </p>
              <p>
                <span>
                  If you have any comments or suggestions relating to improving
                  the accessibility of our site, please don't hesitate to
                  contact our accessibility coordinator Big Tunas Beach Bar &
                  Grill by calling (386) 677-1235 or emailing&nbsp;
                </span>
                <a href={"mailto:bigtunasmarketing@gmail.com"}>
                  bigtunasmarketing@gmail.com
                </a>
                <span>&nbsp;Your feedback will help us make improvements</span>
              </p>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(title: { eq: "Accessibility" }) {
      title
    }
  }
`;
